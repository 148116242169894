import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, BehaviorSubject } from 'rxjs';
import { environment } from '@environments/environment';

const baseUrl = `${environment.apiUrl}/piezas`;
@Injectable()
export class PiezasService extends BehaviorSubject<any> {

  public loading = false;

  constructor(private http: HttpClient) {
    super(null);
  }

  GetCombo() {
    return this.http.post<JSON>(`${baseUrl}/GetCombo`, {}, { withCredentials: true });
  }

  public GetAll(): Observable<any> {
    this.loading = true;
    return this.http.get(baseUrl, { withCredentials: true });
  }

  public GetByID(data:any): Observable<any> {
    this.loading = true;
    return this.http.get(baseUrl + "/" + data.idOF + "/" + data.idpieza + "/" + data.idparte);
  }

  public GetByIDSelectedCombo(data: any): Observable<any> {
    this.loading = true;
    return this.http.get(baseUrl + "/" + data.idOF + "/" + data.idpieza);
  }

  public GetByIDSelectedComboPred(idPiezaPred: number): Observable<any> {
    this.loading = true;
    return this.http.get(baseUrl + "/piezapredcombo/" + idPiezaPred);
  }

  public GetPiezaParteRutaByIdPred(idPiezaPred: number): Observable<any> {
    this.loading = true;
    return this.http.get(baseUrl + "/piezaparterutabypred/" + idPiezaPred);
  }

  public GetReferenciasPredefinidas(): Observable<any> {
    this.loading = true;
    return this.http.get(baseUrl + "/referenciaspred/");
  }
  public GetReferencias(idOF): Observable<any> {
    this.loading = true;
    return this.http.get(baseUrl + "/referencias/" + idOF );
  }

  Get_TipoPieza_Combo() {
    return this.http.post<JSON>(`${baseUrl}/Get_TipoPieza_Combo`, {}, { withCredentials: true });
  }

  public delete(ids: any): Observable<any> {
    return this.http.post<JSON>(baseUrl + "/eliminar" , {ids: ids},{ withCredentials: true });     
  }

  public eliminarpartes(ids: any): Observable<any> {    
    return this.http.post<JSON>(baseUrl + "/eliminarpartes" , {ids: ids},{ withCredentials: true });     
  }

  public eliminarpiezas(ids: any): Observable<any> {    
    return this.http.post<JSON>(baseUrl + "/eliminarpiezas" , {ids: ids},{ withCredentials: true });     
  }

  public existenpartes(ids: any): Observable<any> {    
    return this.http.post<JSON>(baseUrl + "/existenpartes" , {ids: ids},{ withCredentials: true });     
  }

  public existenpiezas(ids: any): Observable<any> {    
    return this.http.post<JSON>(baseUrl + "/existenpiezas" , {ids: ids},{ withCredentials: true });     
  }

  importarCotas(data) {
    return this.http.post<any>(baseUrl + "/importarCotas", data, { withCredentials: true });
  }

  uploadImage(data) {
    return this.http.post<any>(baseUrl + "/uploadimage", data, { withCredentials: true });
  }

  createPiezaPred(id, idOf, esPieza, idParte) { //Versi�n actual
    return this.http.post<any>(baseUrl + "/creardepredefinido", {Id: id, IdOF:idOf, esPieza: esPieza, idParte: idParte}, { withCredentials: true });
  }

  create(data, filename, archivoBase64) {

    data['filename'] = filename;
    data['archivo'] = archivoBase64;
    data.atributos = data.atributos.map(a=> (a.id));

    return this.http.post<any>(baseUrl + "/crear",data, { withCredentials: true });
  }

  update(data, filename, archivoBase64) {

    data['filename'] = filename;
    data['archivo'] = archivoBase64;
    var formData: any = new FormData();
    var fIni:Date =data.fechainicio;
    var fFin: Date = data.fechafin;
    data.atributos = data.atributos.map(a=> (a.id));
    if(fIni !== null && fIni !== undefined && data.fechainicio != "")
      data['fechainicio'] = this.dateToYYYYMMDDtHHmmSSz(fIni);
    else
      data['fechainicio'] = null;
    if(fFin !== null && fFin !== undefined && data.fechafin != "")
      data['fechafin'] = this.dateToYYYYMMDDtHHmmSSz(fFin);
    else
      data['fechafin'] = null;
    if (data['coste'] == null || data['coste'] == undefined) data['coste'] = 0;
    if (data['dureza'] == null || data['dureza'] == undefined) data['dureza'] = 0;
    return this.http.post<any>(baseUrl + "/editar", data , { withCredentials: true });
  }

  update_dependencias(dependenciasPieza) {
    return this.http.post<JSON>(`${baseUrl}/update_dependencias/`, { dt: dependenciasPieza }, { withCredentials: true });
  }

  dateToYYYYMMDDtHHmmSSz(fecha: Date) {
    var ano = fecha.getFullYear();
    var mes = fecha.getMonth() + 1;
    var dia = fecha.getDate(); //getDay da el dia de la semana!
    var hora = fecha.getHours();
    var minutos = fecha.getMinutes();
    var segundos = fecha.getSeconds();
    return ano + '-' + this.addZero(mes) + '-' + this.addZero(dia) + 'T' + this.addZero(hora) + ':' + this.addZero(minutos) + ':' + this.addZero(segundos) + 'Z';
  }

  addZero(n: number) {
    if (n < 10)
      return '0' + n.toString();
    else
      return n.toString();
  }

  duplicar(data) {

    return this.http.post<any>(baseUrl + "/duplicar", {
      id: data.idpieza,
      idparte:data.idparte,
      IDOF: data.idOF,
      Cantidad: data.cantidad,
      Coste: data.coste,
      Dureza: data.dureza,
      EsPieza: data.espieza == undefined ? false : data.espieza,
      Hechos: data.hechos,
      idERPparte: data.idERPparte,
      idERPpieza: data.idERPpieza,
      idReferencia: data.idReferencia,
      limitado: data.limitado,
      NumPlano: data.numplano,
      Orden: data.orden == undefined ? 0 : data.orden,
      Parte: data.parte,
      refParte: data.refparte,
      RutasMultiples: data.rutasmultiples,
      Terminado: data.terminado,
      TieneRutas: data.tienerutas,
      Nombre: data.nombre,
      Descripcion: data.descripcion
    }, { withCredentials: true });

  }

  public cerrarPiezasOperaciones(ids: any, valor: number): Observable<any> {    
    return this.http.post<JSON>(baseUrl + "/cerrarPiezasOperaciones" , {ids, valor},{ withCredentials: true });     
  }

  public cerrarPartesOperaciones(ids: any, valor: number): Observable<any> {    
    return this.http.post<JSON>(baseUrl + "/cerrarPartesOperaciones" , {ids, valor},{ withCredentials: true });     
  }

}
