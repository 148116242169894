<script src="https://cdnjs.cloudflare.com/ajax/libs/d3/5.16.0/d3.js"></script>
<style>
  .k-text-error {
    display: none;
  }

  :host /deep/ .k-grid tbody td {
    white-space: nowrap;
    line-height: 20px;
    padding: 8px 12px;
  }

  :host /deep/ .k-grid .k-grid-content td {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  
</style>

<!-- DIV MAQUINAS -->
<div class="cont-filtro-maquinas-areas bonos clearfix">



  <div class="filtro-areaproductiva-cont" style="width: 230px;">
    <div class="card" style="height: 90px;">
      <div class="card-body">

        <div class="row">



          <!--AREAS PRODUCTIVAS-->
          <div class="col-md-12" style="margin-top: -3px;">
            <kendo-multiselect kendoMultiSelectSummaryTag [(data)]="groupedSeccion" [textField]="'nombre'"
                               [valueField]="'id'" [autoClose]="false" (close)="filtrarMaquinasPorAreaProductivaYGrupo(false)"
                               [(ngModel)]="seccionesSeleccionadas" placeholder="{{ 'secciones' | translate }}" style="width: 200px;"
                               [kendoDropDownFilter]="{operator: 'contains'}">
              <ng-template kendoMultiSelectGroupTagTemplate let-dataItems>
                <span class="k-icon k-i-arrow-s"></span>
                <ng-container *ngIf="dataItems.length == 1">{{ dataItems[0].nombre }}</ng-container>
                <ng-container *ngIf="dataItems.length > 1">
                  {{ dataItems.length }} {{
 'seccionesSeleccionadas' |
                  translate
                  }}
                </ng-container>
              </ng-template>
              <kendo-multiselect-messages noDataText="{{'norecords' | translate}}"></kendo-multiselect-messages>
            </kendo-multiselect>
          </div>

          <!--GRUPOS-->
          <div class="col-md-12" style="margin-top: 3px;">
            <kendo-multiselect kendoMultiSelectSummaryTag [data]="grupos" [textField]="'nombre'" [valueField]="'id'"
                               [autoClose]="false" (close)="filtrarMaquinasPorAreaProductivaYGrupo(false)"
                               [(ngModel)]="gruposSeleccionados" placeholder="{{ 'grupoMaquinas' | translate }}" style="width: 200px;"
                               [kendoDropDownFilter]="{operator: 'contains'}">
              <ng-template kendoMultiSelectGroupTagTemplate let-dataItems>
                <span class="k-icon k-i-arrow-s"></span>
                <ng-container *ngIf="dataItems.length == 1">{{ dataItems[0].nombre }}</ng-container>
                <ng-container *ngIf="dataItems.length > 1">
                  {{ dataItems.length }} {{
 'gruposSeleccionados' | translate
                  }}
                </ng-container>
              </ng-template>
            </kendo-multiselect>
          </div>

        </div>

      </div>
    </div>
  </div>

  <!-- MAQUINAS -->
  <div class="cont-select-maquinas-out" [ngClass]="{'aparecer': aparecer == true}">
    <div class="desplegar-selector-maquina" (click)="desplegarMaquinasClick()">
      <i class="fas fa-ellipsis-v"></i>
    </div>
    <div class="cont-select-maquinas" [ngClass]="{'h-90': aparecer == false}">
      <div *ngFor="let maquina of maquinasMostradas"
           (click)="isOffline = maquina.puestoOffline; selectedMaquina = maquina.id; maquinasDesplegadas = ! maquinasDesplegadas; desplegarMaquinasClick(); cargarBonos();"
           class="clickable" [ngClass]="{'actual': maquina.id==selectedMaquina}">
        <label>{{maquina.nombre}}</label>
        <img class="fas fa-3x" [src]="maquina.imagenBase64">
      </div>
    </div>
  </div>


  <div class="filtro-solofecha">
    <div class="card">
      <!-- FECHA -->
      <div class="card-body">
        
          <label>{{ 'fecha' | translate }}</label>
          <div class="caja">
            <kendo-datepicker [(value)]="fecha" class="form-control" (valueChange)="dateChanged($event)">
            </kendo-datepicker>
          </div>
        
      </div>
    </div>
  </div>

</div>





<!-- GANTT -->
<div class="card">
  <div id="gantt-chart-bonos" style="height: 155px"></div>
</div>


<!--grid berria MARI TODO BONOS-->
<div class="card">
  <ng-template #template let-anchor>
    <span>{{ anchor.nativeElement.innerText }}</span>
  </ng-template>
  <div kendoTooltip
       showOn="none"
       [tooltipTemplate]="template"
       filter=".k-grid td"
       (mouseover)="showGridTooltip($event)">

      <kendo-grid [kendoGridBinding]="dataBonos"
                kendoGridSelectBy="identificadorGrid"
                filterable="menu"
                [selectedKeys]="mySelectionBonos"
                [navigable]="true"
                [sortable]="true"
                scrollable="virtual"
                [rowHeight]="36"
                [height]="500"
                [resizable]="true"
                [pageSize]="50"
                [selectable]="{cell:false, checkboxOnly: false, drag: true, enabled: true, mode: 'multiple'}"
                >
                <!-- (cellClick)="cellClickHandler($event)" -->
                <!-- (dataStateChange)="dataStateChange($event)" -->
                <!-- (pageChange)="pageChangeBonos($event)" -->


<ng-template kendoGridToolbarTemplate position="top">
        <div class="float-left">
          <!-- <button (click)="clickEditar(popupEditar)" class="btn btn-success btn-sm mr-1">{{ 'editar' | translate}}</button> -->
          <button [disabled]="mySelectionBonos?.length == 0 || comprobarOFUnica()" (click)="clickEditarOF(popupEditar)" class="btn btn-success btn-sm mr-1" >{{ 'editarOF' | translate}}</button>
          <button [disabled]="mySelectionBonos?.length == 0" (click)="clickeditarUsuario(popupEditar)" class="btn btn-success btn-sm mr-1" >{{ 'editarOperario' | translate}}</button>
          <button (click)="clickAnnadirOperacion(popupAnnadirOperacion)" class="btn btn-success btn-sm mr-1">{{ 'annadirOperacion' | translate}}</button>
          <button [disabled]="mySelectionBonos?.length != 1" (click)="clickAsignarPerdida(popupAsignarPerdida)" class="btn btn-success btn-sm mr-1">{{ 'asignarPerdida' | translate}}</button>
          <button [disabled]="mySelectionBonos?.length == 0" (click)="clickDistribucionTiempos(popupDistribucionTiempos)" class="btn btn-success btn-sm mr-1">{{ 'asignarPreparacion' | translate}}</button>
          <!-- <button (click)="clickEditarTipo(popupEditar)" class="btn btn-success btn-sm mr-1">{{ 'editarTipo' | translate}}</button> -->
        </div>

      <!--
        <div class="float-right">
          <button (click)="clickTodo()" class="btn btn-success btn-sm mr-1">{{ 'todo' | translate}}</button>
          <button (click)="clickSinOF()" class="btn btn-success btn-sm mr-1">{{ 'sinOF' | translate}}</button>
          <button (click)="clickSinOperario()" class="btn btn-success btn-sm mr-1">{{ 'sinOperario' | translate}}</button>
          <button (click)="clickSinPerdida()" class="btn btn-success btn-sm mr-1">{{ 'sinPerdida' | translate}}</button>
          <button (click)="clickEjecuciones()" class="btn btn-success btn-sm mr-1">{{ 'ejecuciones' | translate}}</button>
          <button (click)="clickParadas()" class="btn btn-success btn-sm mr-1">{{ 'paradas' | translate}}</button>
          <button (click)="clickMicroParadas()" class="btn btn-success btn-sm mr-1">{{ 'microparadas' | translate}}</button>
          <button (click)="clickPreparaciones()" class="btn btn-success btn-sm mr-1">{{ 'preparaciones' | translate}}</button>
          <button (click)="cickMantenimientos()" class="btn btn-success btn-sm mr-1">{{ 'mantenimientos' | translate}}</button>
          <button (click)="clickAlarmas()" class="btn btn-success btn-sm mr-1">{{ 'alarmas' | translate}}</button>
          <button (click)="clickApagadas()" class="btn btn-success btn-sm mr-1">{{ 'apagadas' | translate}}</button>
        </div>
      -->
      <div  class="float-right" >
        <button type="button" class="btn btn-success btn-sm mr-1" (click)="guardarValidar()" [disabled]="this.user.bonos<2">{{ 'validar' | translate }}</button>
        <button type="button" class="btn btn-success btn-sm mr-1" (click)="advertenciaDesvalidar()" [disabled]="this.user.bonos<2">{{ 'desvalidar' | translate }}</button>
        <button type="button" class="btn btn-primary btn-sm mr-1" (click)="guardarActualizar()" [disabled]="this.user.bonos<2">{{ 'actualizar' | translate }}</button>
      </div>
      </ng-template>

      <!--checkbox-->
      <kendo-grid-checkbox-column width="3%" showSelectAll="false">
        <!-- <ng-template kendoGridHeaderTemplate>
          <input class="k-checkbox" id="selectAllCheckboxId" kendoGridSelectAllCheckbox
                 [state]="selectAllState" 
                 (selectAllChange)="onSelectAllChange($event)">
          <label class="k-checkbox-label" for="selectAllCheckboxId"></label>
        </ng-template> -->
      </kendo-grid-checkbox-column>


                <!-- <kendo-grid-column field="procesos_Tipo" title="" width="3%" [filterable]="false">
                  <ng-template kendoGridCellTemplate let-dataItem>
                    <span class="cuadrado-color-grid" [ngClass]="dataItem.procesos_Tipo">{{ dataItem.procesos_Tipo | translate }}</span>
                  </ng-template>
                </kendo-grid-column> -->
                <!-- <kendo-grid-column field="id" title="{{ 'numeroOF_OF' | translate }}" width="16%"></kendo-grid-column> KENTZEKOA -->
                <!-- <kendo-grid-column field="idOF" title="{{ 'idOF' | translate }}" width="16%"></kendo-grid-column> KENTZEKOA -->
                <!-- <kendo-grid-column field="idHO" title="{{ 'idHO' | translate }}" width="16%"></kendo-grid-column> KENTZEKOA -->
                <kendo-grid-column field="numeroOF_OF" title="{{ 'refOF' | translate }}" width="6%"></kendo-grid-column>
                <kendo-grid-column field="pieza" title="{{ 'pieza' | translate }}" width="10%"></kendo-grid-column>
                <kendo-grid-column field="parte" title="{{ 'parte' | translate }}" width="10%" *ngIf="!user.ocultarParte"></kendo-grid-column>
                <kendo-grid-column field="nombre_Operacion" title="{{ 'operacion' | translate }}" width="10%"></kendo-grid-column>
                
                  
                
                <kendo-grid-column field="colada" *ngIf="user.verColada" title="{{ 'colada' | translate }}" width="10%"></kendo-grid-column>
                <kendo-grid-column field="lote" *ngIf="user.verLote" title="{{ 'lote' | translate }}" width="10%"></kendo-grid-column>
                <kendo-grid-column field="nSerie" *ngIf="user.verNSerie" title="{{ 'nserie' | translate }}" width="7%"></kendo-grid-column>
                <kendo-grid-column field="cantidadTerminada" title="{{ 'terminado' | translate }}" width="7%"  [style]="{'text-align': 'right'}">
                  <ng-template kendoGridCellTemplate let-dataItem>
                    <span [style]="{'text-align': 'right'}">
                      {{ dataItem.cantidadTerminada }} / {{ dataItem.cantidadTotalAHacer }}</span>
                  </ng-template>
                </kendo-grid-column>
                <kendo-grid-column field="cantidadAchatarrada" title="{{ 'achatarradas' | translate }}" width="7%"  [style]="{'text-align': 'right'}"></kendo-grid-column>
                <kendo-grid-column field="cantidadApartada" title="{{ 'apartada' | translate }}" width="7%"  [style]="{'text-align': 'right'}"></kendo-grid-column>
                
                <!--operario-->
                <kendo-grid-column field="operarioNombre" title="{{ 'operario' | translate }}" width="8%"
                class="celda-tooltip-externo">
                <ng-template kendoGridCellTemplate let-dataItem>
                  <span class="tooltip-consumibles-contenido">
                    
                      <span *ngIf="dataItem.operario!='undefined' && dataItem.operario!='Dundefined'" class="grid-circulo"
                        [style.background-color]="blue">{{dataItem.iniciales}}</span>
                      <span *ngIf="dataItem.operario=='undefined' || dataItem.operario=='Dundefined'" class="grid-circulo"><i class="fas fa-user-alt"></i></span>
                    
                    <span class="tooltiptext">
                        <label *ngIf="dataItem.operario !='Dundefined' && dataItem.operario !='undefined'">
                          {{dataItem.operario}}
                        </label>
                        <label *ngIf="dataItem.operario =='Dundefined' || dataItem.operario =='undefined'">
                          {{'desconocido' | translate}}
                        </label>
                    </span>
                  </span>
                </ng-template>
              </kendo-grid-column>

               
                <!--<kendo-grid-column field="tiempoEstHH" title="{{ 'tiempoEstimado' | translate }}" width="5%"></kendo-grid-column>-->
                
                <kendo-grid-column field="horaInicio" title="{{ 'inicio' | translate }}" width="7%" [style]="{'text-align': 'right'}">
                  <!--<ng-template kendoGridCellTemplate let-dataItem>{{dataItem.fechaini | kendoDate }} {{dataItem.fechaini | kendoDate:'HH:mm:ss' }}</ng-template>-->
                </kendo-grid-column>
                <kendo-grid-column field="horaFin" title="{{ 'fin' | translate }}" width="7%" [style]="{'text-align': 'right'}">
                  <!--<ng-template kendoGridCellTemplate let-dataItem>{{dataItem.fechafin | kendoDate }} {{dataItem.fechafin | kendoDate:'HH:mm:ss' }}</ng-template>-->
                </kendo-grid-column>
                <kendo-grid-column field="tiempoRealHH" title="{{ 'tTotal' | translate }}" width="7%" [style]="{'text-align': 'right'}"></kendo-grid-column>
                <kendo-grid-column field="terminado" title="{{ 'terminado' | translate }}" width="7%" [style]="{'text-align': 'center'}">
                  <ng-template [style]="{'text-align': 'center'}" kendoGridCellTemplate let-dataItem>
                    <input type="checkbox" [checked]="dataItem.terminado" disabled />
                  </ng-template>
                </kendo-grid-column>

                <!-- pieza terminada -->
                <kendo-grid-column *ngIf="user.verNSerie" field="piezaTerminada" title="{{ 'piezaTerminada' | translate }}" width="7%" [style]="{'text-align': 'center'}">
                  <ng-template [style]="{'text-align': 'center'}" kendoGridCellTemplate let-dataItem>
                    <input type="checkbox" [checked]="dataItem.piezaTerminada" disabled />
                  </ng-template>
                </kendo-grid-column>
               
                <kendo-grid-column field="validado" title="{{ 'validado' | translate }}" width="7%" [style]="{'text-align': 'center'}">
                  <ng-template [style]="{'text-align': 'center'}" kendoGridCellTemplate let-dataItem>
                    <input type="checkbox" [checked]="dataItem.validado" disabled />
                  </ng-template>
                </kendo-grid-column>
               
                <kendo-grid-column field="perdida" title="{{ 'perdida' | translate }}" width="7%" [style]="{'text-align': 'right'}"></kendo-grid-column>
               
       

                <!--<kendo-grid-column field="descripcion" title="{{ 'descripcion' | translate }}" width="10%"></kendo-grid-column>

                <kendo-grid-column field="perdida" title="{{ 'perdida' | translate }}" width="10%"></kendo-grid-column>
                <kendo-grid-column width="5%" field="validado" [style]="{'text-align': 'left'}" title="{{ 'validado' | translate }}" filter="boolean" [headerStyle]="{'text-align': 'center'}">
                  <ng-template kendoGridCellTemplate let-dataItem>
                    <input type="checkbox" [checked]="dataItem.validado" disabled />
                  </ng-template>
                </kendo-grid-column>-->



                <!-- ESTADO -->
                <kendo-grid-column field="claseEstado" title="{{ 'estado' | translate }}" width="10%"
                [style]="{'text-align': 'center'}" class="celda-tooltip-externo p-0">
                <ng-template kendoGridHeaderTemplate let-column>
                  <label>
                      <span class="tooltip-consumibles-contenido">
                        <i class="fas fa-info-circle mr-2"></i>
                        <span [attr.contenido]="contenidoTooltipTiempos" class="tooltipTextHeader"></span>
                      </span>
                    {{ 'Tiempos' | translate }}
                  </label>
                </ng-template>
                <ng-template kendoGridCellTemplate let-dataItem>
                  <span class="tooltip-consumibles-contenido">

                    <div class="graf-barr-part-cont "  id="barra1">
                     <!-- <div class="textobarra">
                        <Label>{{dataItem.TPorcentajeEjecucion}}</Label>
                        <Label>{{dataItem.TPorcentajePreparacion}}</Label>
                      </div> -->
                      <div class="graf-barr-part-multi fondo-color-preparacion" id="barraTiemposPorcentajePreparacion" [style.width.%]="(dataItem.TPorcentajePreparacion)">
                      </div>
                      <div class="graf-barr-part-multi fondo-color-ejecucion" id="barraTiemposPorcentajeEjecucion" [style.width.%]="(dataItem.TPorcentajeEjecucion)" >
                      </div>
                      <div class="graf-barr-part-multi fondo-color-parada" id="barraTiemposPorcentajeParada" [style.width.%]="(dataItem.TPorcentajeParada)" >
                      </div>
                      <div class="graf-barr-part-multi fondo-color-mantenimiento" id="barraTiemposPorcentajeMantenimiento" [style.width.%]="(dataItem.TPorcentajeMantenimiento)">
                      </div>
                      <div class="graf-barr-part-multi fondo-color-alarma" id="barraTiemposPorcentajeAlarma" [style.width.%]="(dataItem.TPorcentajeAlarma)">
                      </div>
                      <div class="graf-barr-part-multi fondo-color-apagada" id="barraTiemposPorcentajeApagado" [style.width.%]="(dataItem.TPorcentajeApagado)">
                      </div>
                      <div class="graf-barr-part-multi fondo-color-microparada" id="barraTiemposPorcentajeMicroparada" [style.width.%]="(dataItem.TPorcentajeMicroParada)">
                      </div>
                      
                    </div>

                    <span class="tooltiptext text-left"  >
                      <label class="tooltipDestacado" *ngIf="dataItem.tiempoPreparacion>0" >
                        {{ 'tPreparacion' | translate }} = {{dataItem.THHPreparacion}}
                      </label>
                      
                      <label class="tooltipDestacado" *ngIf="dataItem.tiempoEjecucion>0" >
                        {{ 'tEjecucion' | translate }} = {{dataItem.THHEjecucion}}
                      </label>
                      <label class="tooltipDestacado" *ngIf="dataItem.tiempoParada>0" >
                        {{ 'TParada' | translate }} = {{dataItem.THHParada}}
                      </label>
                      <label class="tooltipDestacado" *ngIf="dataItem.tiempoMantenimiento>0" >
                        {{ 'TMantenimiento' | translate }} = {{dataItem.THHMantenimiento}}
                      </label>
                      <label class="tooltipDestacado" *ngIf="dataItem.tiempoAlarma>0" >
                        {{ 'TAlarma' | translate }} = {{dataItem.THHAlarma}}
                      </label>
                      <label class="tooltipDestacado" *ngIf="dataItem.tiempoApagada>0" >
                        {{ 'TApagado' | translate }} = {{dataItem.THHApagado}}
                      </label>
                      <label class="tooltipDestacado" *ngIf="dataItem.tiempoMicro>0" >
                        {{ 'TMicroparada' | translate }} = {{dataItem.THHMicroParada}}
                      </label>
                     </span>
                
                    
                  </span>
                </ng-template>
              </kendo-grid-column>

              <kendo-grid-messages filter="{{'filter' | translate}}" filterAfterOperator="{{'filterAfterOperator' | translate}}"
              filterAfterOrEqualOperator="{{'filterAfterOrEqualOperator' | translate}}"
              filterAndLogic="{{'filterAndLogic' | translate}}" filterBeforeOperator="{{'filterBeforeOperator' | translate}}"
              filterBeforeOrEqualOperator="{{'filterBeforeOrEqualOperator' | translate}}"
              filterBooleanAll="{{'filterBooleanAll' | translate}}" filterClearButton="{{'filterClearButton' | translate}}"
              filterContainsOperator="{{'filterContainsOperator' | translate}}"
              filterDateToday="{{'filterDateToday' | translate}}" filterDateToggle="{{'filterDateToggle' | translate}}"
              filterEndsWithOperator="{{'filterEndsWithOperator' | translate}}"
              filterEqOperator="{{'filterEqOperator' | translate}}" filterFilterButton="{{'filterFilterButton' | translate}}"
              filterGtOperator="{{'filterGtOperator' | translate}}" filterGteOperator="{{'filterGteOperator' | translate}}"
              filterIsEmptyOperator="{{'filterIsEmptyOperator' | translate}}" filterIsFalse="{{'filterIsFalse' | translate}}"
              filterIsNotEmptyOperator="{{'filterIsNotEmptyOperator' | translate}}"
              filterIsNotNullOperator="{{'filterIsNotNullOperator' | translate}}"
              filterIsNullOperator="{{'filterIsNullOperator' | translate}}" filterIsTrue="{{'filterIsTrue' | translate}}"
              filterLtOperator="{{'filterLtOperator' | translate}}" filterLteOperator="{{'filterLteOperator' | translate}}"
              filterNotContainsOperator="{{'filterNotContainsOperator' | translate}}"
              filterNotEqOperator="{{'filterNotEqOperator' | translate}}"
              filterNumericDecrement="{{'filterNumericDecrement' | translate}}"
              filterNumericIncrement="{{'filterNumericIncrement' | translate}}" filterOrLogic="{{'filterOrLogic' | translate}}"
              filterStartsWithOperator="{{'filterStartsWithOperator' | translate}}" loading="{{'loading' | translate}}"
              groupPanelEmpty="{{'groupPanelEmpty' | translate}}" lock="{{'lock' | translate}}" unlock="{{'unlock' | translate}}"
              noRecords="{{'norecords' | translate}}" pagerItems="" pagerOf="/"></kendo-grid-messages>
</kendo-grid>
</div>
</div>




<!-- POPUP -->
<ng-template #popupEditar let-modal>
  <div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title">{{ 'editar' | translate }}</h4>
    <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <div class="row">

    </div>
    <!-- ROW 1 -->
    <div class="row" [hidden]="!visibleTipo">
      <div class="col-md-6">
        <div class="form-group">
          <label>{{ 'tipo' | translate }}</label>
          <div class="caja">
            <kendo-combobox [data]="Jtipos"
                            [textField]="'text'"
                            [valueField]="'value'"
                            [(value)]="JtiposSelected"
                            (valueChange)="tipoChanged($event)"
                            placeholder="{{ 'tipo' | translate }}"
                            [kendoDropDownFilter]="{operator: 'contains'}">
            </kendo-combobox>
          </div>
        </div>
      </div>
      <div class="col-md-6">
        <div class="form-group">
          <label>{{ 'perdida' | translate }}</label>
          <div class="caja">
            <kendo-combobox [data]="Jperdidas"
                            [textField]="'text'"
                            [valueField]="'value'"
                            [(value)]="JperdidasSelected"
                            placeholder="{{ 'perdida' | translate }}"
                            [kendoDropDownFilter]="{operator: 'contains'}">
            </kendo-combobox>
          </div>
        </div>
      </div>
    </div>
    <!-- ROW 1.1 -->
    <div class="row" [hidden]="!visibleTipo">
      <div class="col-md-6">
        <div class="form-group">
          <label>{{ 'inicio' | translate }}</label>
          <div class="caja">
            <kendo-datetimepicker [(value)]="fechaIni"
                                  class="form-control"
                                  [disabled]="true">
            </kendo-datetimepicker>
          </div>
        </div>
      </div>
      <div class="col-md-6">
        <div class="form-group">
          <label>{{ 'fin' | translate }}</label>
          <div class="caja">
            <kendo-datetimepicker [(value)]="fechaFin"
                                  class="form-control"
                                  [disabled]="true">
            </kendo-datetimepicker>
          </div>
        </div>
      </div>
    </div>
    <!-- ROW 2 -->
    <div class="row" [hidden]="!visibleProyecto">
      <div class="col-md-6">
        <div class="form-group">
          <label>{{ 'OF' | translate }}</label>
          <div class="caja">
            <kendo-combobox [data]="Jproyectos"
                            [textField]="'text'"
                            [valueField]="'value'"
                            (valueChange)="ProyectoValueChanged($event)"
                            [(value)]="JproyectoSelected"
                            [class.requerido]="requireProyecto"
                            placeholder="{{ 'OF' | translate }}"
                            [kendoDropDownFilter]="{operator: 'contains'}">
            </kendo-combobox>
          </div>
        </div>
      </div>
      <div class="col-md-6">
        <div class="form-group">
          <label>{{ 'pieza' | translate }}</label>
          <div class="caja">
            <kendo-combobox [data]="Jpiezas"
                            [textField]="'text'"
                            [valueField]="'value'"
                            (valueChange)="PiezaValueChanged($event)"
                            [(value)]="JpiezasSelected"
                            [class.requerido]="requirePieza"
                            placeholder="{{ 'pieza' | translate }}"
                            [kendoDropDownFilter]="{operator: 'contains'}">
            </kendo-combobox>
          </div>
        </div>
      </div>
    </div>
    <!-- ROW 2.2 -->
    <div class="row" [hidden]="!visibleProyecto">
      <div class="col-md-6" *ngIf="!ocultarPartes">
        <div class="form-group">
          <label>{{ 'parte' | translate }}</label>
          <div class="caja">
            <kendo-combobox [data]="Jpartes"
                            [textField]="'text'"
                            [valueField]="'value'"
                            (valueChange)="ParteValueChanged($event)"
                            [(value)]="JpartesSelected"
                            [class.requerido]="requireParte"
                            placeholder="{{ 'parte' | translate }}"
                            [kendoDropDownFilter]="{operator: 'contains'}">
            </kendo-combobox>
          </div>
        </div>
      </div>
      <div class="col-md-6">
        <div class="form-group">
          <label>{{ 'operacion' | translate }}</label>
          <div class="caja">
            <kendo-combobox [data]="Jprogramas"
                            [textField]="'text'"
                            [valueField]="'value'"
                            (valueChange)="ProgramaValueChanged($event)"
                            [(value)]="JprogramasSelected"
                            [class.requerido]="requireOperacion"
                            placeholder="{{ 'operacion' | translate }}"
                            [kendoDropDownFilter]="{operator: 'contains'}">
            </kendo-combobox>
          </div>
        </div>
      </div>
    </div>
    <!-- ROW 2.2.2 -->
    <div class="row" [hidden]="!visibleProyecto">
      
      <!-- <div class="col-md-3" *ngIf="(nSerieTextInput) && (JnSerieSelected == undefined)">
        <div class="form-group">
          <kendo-label text="{{ 'nuevonSerie' | translate }}"></kendo-label>
          <div class="caja">
            <kendo-textbox [(value)]="nuevoNserie" [maxlength]="50"></kendo-textbox>
          </div>
        </div>
      </div> -->

      <div class="col-md-3" >
        <!-- *ngIf="user.verColada" -->
        <div class="form-group">
          <kendo-label text="{{ 'colada' | translate }}"></kendo-label>
          <div class="caja">
              <kendo-combobox [data]="comboColada"
                          [textField]="'colada'"
                          [valueField]="'colada'"
                          [(value)]="JnColadaSelected"
                          placeholder="{{ 'colada' | translate }}"
                          [kendoDropDownFilter]="{operator: 'contains'}"
                          [allowCustom]="true"
                          (valueChange)="coladaChanged($event)">
          </kendo-combobox>
          </div>
        </div>
      </div>
      
        <div class="col-md-3" >
          <!-- *ngIf="usaLotes && user.verLote" -->
          <div class="form-group">
            <kendo-label text="{{ 'lote' | translate }}"></kendo-label>
            <div class="caja">

                <kendo-combobox [data]="comboLote"
                            [textField]="'lote'"
                            [valueField]="'lote'"
                            [(value)]="JnLotesSelected"
                            placeholder="{{ 'lote' | translate }}"
                            [kendoDropDownFilter]="{operator: 'contains'}"
                            [allowCustom]="true"
                            (valueChange)="loteChanged($event)">
            </kendo-combobox>
            </div>
          </div>
        </div>

        

        <div class="col-md-3">
          <!-- *ngIf="user.verNSerie" -->
          <div class="form-group">
            <label>{{ 'nserie' | translate }}</label>
            <div class="caja">
              <kendo-combobox [data]="comboNSerie"
                              [textField]="'nSerie'"
                              [valueField]="'rn'"
                              [(value)]="JnSerieSelected"
                              placeholder="{{ 'nserie' | translate }}"
                              [kendoDropDownFilter]="{operator: 'contains'}"
                              [allowCustom]="true"
                              (valueChange)="serieChanged($event)">
              </kendo-combobox>
            </div>
          </div>
        </div>

    </div>
    <!-- ROW 3 before -->
    <div class="row" [hidden]="!visibleProyecto" *ngIf="!distribucionTiemposOpen">
      <!-- <div class="col-md-3" *ngIf="usaLotes && verCantidades">
        <div class="form-group">
          <kendo-label text="{{ 'cantidadTerminada' | translate }}"></kendo-label>
          <div class="caja">
            <div class="caja">
              <kendo-numerictextbox [decimals]="0" [min]="1" [max]="999999" maxlength="50" [(value)]="PPcantidadTerminada"></kendo-numerictextbox>
              <kendo-label text="&nbsp;/&nbsp;{{PPcantidadTotal}}"></kendo-label>
            </div>
          </div>
        </div>
      </div>
      <div class="col-md-3" *ngIf="usaLotes && verCantidades">
        <div class="form-group">
          <kendo-label text="{{ 'cantidadAchatarrada' | translate }}"></kendo-label>
          <div class="caja">
            <div class="caja">
              <kendo-numerictextbox [decimals]="0" [min]="1" [max]="999999" maxlength="50" [(value)]="PPcantidadAchatarrada"></kendo-numerictextbox> 
             
            </div>
          </div>
        </div>
      </div>
      <div class="col-md-3" *ngIf="usaLotes && verCantidades">
        <div class="form-group">
          <kendo-label text="{{ 'cantidadApartada' | translate }}"></kendo-label>
          <div class="caja">
            <div class="caja">
               <kendo-numerictextbox [decimals]="0" [min]="1" [max]="999999" maxlength="50" [(value)]="PPcantidadApartada"></kendo-numerictextbox>
            </div>
          </div>
        </div>
      </div> -->
      
      <div class="col-md-2" *ngIf="usaLotes && verCantidades">
        <div class="form-group">
          <kendo-label text="{{'terminado' |translate}}"></kendo-label>
          <div class="caja">
            <kendo-switch id="terminado" [(ngModel)]="terminado" [onLabel]="' '" [offLabel]="' '"> </kendo-switch>
          </div>
        </div>
      </div>

      <div class="col-md-2" *ngIf="user.verNSerie && verCantidades">
        <div class="form-group">
          <kendo-label text="{{'piezaTerminada' |translate}}"></kendo-label>
          <div class="caja">
            <kendo-switch id="piezaTerminada" [(ngModel)]="piezaTerminada" [onLabel]="' '" [offLabel]="' '" (valueChange)="piezaTerminadaChange()"> </kendo-switch>
          </div>
        </div>
      </div>

      <div class="col-md-12">
        <kendo-grid [kendoGridBinding]="hmiCantidadesAsignadas" [sortable]="true" [navigable]="true"
        kendoGridSelectBy="id" [selectedKeys]="HMI_cantidadesPiezas_seleccionadas" [rowClass]="rowCallback"
        filterable="menu" [height]="250">
          <!-- botoiak -->
          <ng-template kendoGridToolbarTemplate>
            <button type="button" class="btn btn-primary m-1" (click)="btnAnnadir_HMI_CantidadesPiezasAsignadas()">{{ 'anadir' | translate }}</button>
            <button type="button" [disabled]="HMI_cantidadesPiezas_seleccionadas.length == 0" class="btn btn-danger m-1" (click)="btnEliminar_HMI_CantidadesPiezasAsignadas()">{{'eliminar' | translate }}</button>
          </ng-template>
          <!-- checkbox-->
          <kendo-grid-checkbox-column [width]="5" showSelectAll="true"></kendo-grid-checkbox-column>
          <!-- fecha -->
          <kendo-grid-column width="10%" field="fecha" title="{{ 'fecha' | translate}}"> 
            <ng-template kendoGridCellTemplate let-dataItem>
              <span *ngIf="!dataItem.insertar"> {{dataItem.fecha | kendoDate:'HH:mm:ss'}} </span>
              <kendo-textbox *ngIf="dataItem.insertar" [(value)]="dataItem.fechaAnnadir" (valueChange)="onChangeFecha($event, dataItem.id)"></kendo-textbox>
            </ng-template>
          </kendo-grid-column>
          <!-- nombre maquina-->
          <kendo-grid-column width="10%" field="nombreMaquina" title="{{ 'maquina' | translate}}"> </kendo-grid-column>
          <!-- operario -->
          <kendo-grid-column width="10%" field="nombreOperario" title="{{ 'operario' | translate}}"> 
            <ng-template kendoGridCellTemplate let-dataItem>
              <span *ngIf="!dataItem.insertar"> {{dataItem.nombreOperario}} </span>
              <kendo-combobox *ngIf="dataItem.insertar" [data]="Joperarios"
                            [textField]="'text'"
                            [valueField]="'valor'"
                            [(value)]="dataItem.idOperario"
                            placeholder="{{ 'operario' | translate }}"
                            [kendoDropDownFilter]="{operator: 'contains'}">
              </kendo-combobox>
            </ng-template>
          </kendo-grid-column>
          <!-- cantidad terminada -->
          <kendo-grid-column width="10%" field="cantidadTerminada" title="{{ 'terminadas' | translate}}">
          <ng-template kendoGridCellTemplate let-dataItem>
            <kendo-numerictextbox class="form-control" [min]="0" [max]="99999" [autoCorrect]="true" [step]="1" [(ngModel)]="dataItem.cantidadTerminada"
                                  format="#"> 
            </kendo-numerictextbox>
          </ng-template>
          </kendo-grid-column>
          <!-- cantidad achatarrada -->
          <kendo-grid-column width="10%" field="cantidadAchatarrada" title="{{ 'achatarradas' | translate}}">
          <ng-template kendoGridCellTemplate let-dataItem>
            <kendo-numerictextbox class="form-control" [min]="0" [max]="99999" [autoCorrect]="true" [step]="1" [(ngModel)]="dataItem.cantidadAchatarrada"
                                  format="#"> 
            </kendo-numerictextbox>
          </ng-template>
          </kendo-grid-column>
          <!-- cantidad apartada -->
          <kendo-grid-column width="10%" field="cantidadApartada" title="{{ 'apartadas' | translate}}">
          <ng-template kendoGridCellTemplate let-dataItem>
            <kendo-numerictextbox class="form-control" [min]="0" [max]="99999" [autoCorrect]="true" [step]="1" [(ngModel)]="dataItem.cantidadApartada"
                                  format="#"> 
            </kendo-numerictextbox>
          </ng-template>
          </kendo-grid-column>
          <kendo-grid-messages filter="{{'filter' | translate}}" filterAfterOperator="{{'filterAfterOperator' | translate}}"
          filterAfterOrEqualOperator="{{'filterAfterOrEqualOperator' | translate}}"
          filterAndLogic="{{'filterAndLogic' | translate}}" filterBeforeOperator="{{'filterBeforeOperator' | translate}}"
          filterBeforeOrEqualOperator="{{'filterBeforeOrEqualOperator' | translate}}"
          filterBooleanAll="{{'filterBooleanAll' | translate}}" filterClearButton="{{'filterClearButton' | translate}}"
          filterContainsOperator="{{'filterContainsOperator' | translate}}"
          filterDateToday="{{'filterDateToday' | translate}}" filterDateToggle="{{'filterDateToggle' | translate}}"
          filterEndsWithOperator="{{'filterEndsWithOperator' | translate}}"
          filterEqOperator="{{'filterEqOperator' | translate}}" filterFilterButton="{{'filterFilterButton' | translate}}"
          filterGtOperator="{{'filterGtOperator' | translate}}" filterGteOperator="{{'filterGteOperator' | translate}}"
          filterIsEmptyOperator="{{'filterIsEmptyOperator' | translate}}" filterIsFalse="{{'filterIsFalse' | translate}}"
          filterIsNotEmptyOperator="{{'filterIsNotEmptyOperator' | translate}}"
          filterIsNotNullOperator="{{'filterIsNotNullOperator' | translate}}"
          filterIsNullOperator="{{'filterIsNullOperator' | translate}}" filterIsTrue="{{'filterIsTrue' | translate}}"
          filterLtOperator="{{'filterLtOperator' | translate}}" filterLteOperator="{{'filterLteOperator' | translate}}"
          filterNotContainsOperator="{{'filterNotContainsOperator' | translate}}"
          filterNotEqOperator="{{'filterNotEqOperator' | translate}}"
          filterNumericDecrement="{{'filterNumericDecrement' | translate}}"
          filterNumericIncrement="{{'filterNumericIncrement' | translate}}" filterOrLogic="{{'filterOrLogic' | translate}}"
          filterStartsWithOperator="{{'filterStartsWithOperator' | translate}}" loading="{{'loading' | translate}}"
          groupPanelEmpty="{{'groupPanelEmpty' | translate}}" lock="{{'lock' | translate}}" unlock="{{'unlock' | translate}}"
          noRecords="{{'norecords' | translate}}" pagerItems="" pagerOf="/"></kendo-grid-messages>
        </kendo-grid>    
      </div>
    </div>

    <!-- ROW 3 -->
    <div class="row" [hidden]="!visibleOperario" *ngIf="!distribucionTiemposOpen">
      <div class="col-md-3">
        <div class="form-group">
          <label>{{ 'operario' | translate }}</label>
          <div class="caja">
            <kendo-combobox [data]="Joperarios"
                            [textField]="'text'"
                            [valueField]="'valor'"
                            [(value)]="JoperariosSelected"
                            placeholder="{{ 'operario' | translate }}"
                            [kendoDropDownFilter]="{operator: 'contains'}">
            </kendo-combobox>
          </div>
        </div>
      </div>
    </div>
    <!-- ROW 4 -->
    <div class="row" [hidden]="!visibleObservaciones" *ngIf="!distribucionTiemposOpen">
      <div class="col-md-12">
        <label>{{ 'descripcion' | translate }}</label>
        <textarea kendoTextArea [(value)]="Jdescripcion"></textarea>
      </div>
    </div>
    <!-- ROW 5 -->
    <div class="row" [hidden]="!(JperdidasSelected && visibleTipo)" *ngIf="!distribucionTiemposOpen">
      <div class="col-md-12">
        <label>{{ 'observacion' | translate }}</label>
        <textarea kendoTextArea [(value)]="Jobservacion"></textarea>
      </div>
    </div>


  </div>
    <div class="modal-footer">
      <button type="button" class="btn btn-danger" (click)="modal.dismiss('cancel click')">{{ 'cancelar' | translate }}</button>
      <!-- Esta opcion actualiza las lineas al momento de dar aceptar -->
      <button type="button" class="btn btn-primary" (click)="actualizarJBonos()" [disabled]="this.user.bonos<2" *ngIf="!distribucionTiemposOpen">{{ 'aceptar' | translate }}</button>
      <!-- En el caso de que se tenga que asignar una preparacion no se deben actualizar las lineas al momento por lo que se hace otra funcion-->
      <button type="button" class="btn btn-primary" (click)="OFSeleccionada()" [disabled]="this.user.bonos<2" *ngIf="distribucionTiemposOpen">{{ 'aceptar' | translate }}</button>
    </div>
</ng-template>

<!-- POPUP ANNADIR OPERACION -->
<ng-template #popupAnnadirOperacion let-modal>
  <div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title">{{ 'annadirOperacion' | translate }}</h4>
    <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <!-- fechaIni: {{fechaIni}}<br>
    fechaFin: {{fechaFin}}<br> -->
    <!-- FECHA -->
    <div class="row">
      <div class="col-md-6">
        <div class="form-group">
          <label>{{ 'inicio' | translate }}</label>
          <div class="caja">
            <kendo-timepicker [(value)]="fechaIni"
                                  class="form-control"
                                  [min]="fechaMin_AnnadirOperacion"
                                  [max]="fechaMax_AnnadirOperacion"
                                  (valueChange)="periodoValido()">
            </kendo-timepicker>
          </div>
        </div>
      </div>
      <div class="col-md-6">
        <div class="form-group">
          <label>{{ 'fin' | translate }}</label>
          <div class="caja">
            <kendo-timepicker [(value)]="fechaFin"
                                  class="form-control"
                                  [min]="fechaMin_AnnadirOperacion"
                                  [max]="fechaMax_AnnadirOperacion"
                                  (valueChange)="periodoValido()">
            </kendo-timepicker>
          </div>
        </div>
      </div>
    </div>

    <div class="row" *ngIf="!esSoloParada">
      <div class="col-md-12">
        <span> * {{ 'noAnnadirOperacion' | translate }} </span>
      </div>
    </div>

    <!-- OPERARIO -->
    <div class="row" *ngIf="esSoloParada">
      <!-- OF -->
      <div class="col-md-12">
          <label>{{ 'operario' | translate }}</label>
          <div class="caja">
            <kendo-combobox [data]="Joperarios"
                            [textField]="'text'"
                            [valueField]="'valor'"
                            [(value)]="JoperariosSelected"
                            placeholder="{{ 'operario' | translate }}"
                            [kendoDropDownFilter]="{operator: 'contains'}">
            </kendo-combobox>
          </div>
      </div>

    </div>

    <!-- OF, PIEZA, PARTE, OPERACION -->
    <div class="row" *ngIf="esSoloParada">
      <!-- OF -->
      <div class="col-md-3">
        <div class="form-group">
          <label>{{ 'of' | translate }}</label>
          <div class="caja">
            <kendo-combobox [data]="Jproyectos"
                            [textField]="'text'"
                            [valueField]="'value'"
                            (valueChange)="ofValueChanged($event)"
                            [(value)]="JproyectoSelected"
                            [class.requerido]="requireProyecto"
                            placeholder="{{ 'proyecto' | translate }}"
                            [kendoDropDownFilter]="{operator: 'contains'}">
            </kendo-combobox>
          </div>
        </div>
      </div>

      <!-- PIEZA -->
      <div class="col-md-3">
        <div class="form-group">
          <label>{{ 'pieza' | translate }}</label>
          <div class="caja">
            <kendo-combobox [data]="Jpiezas"
                            [textField]="'text'"
                            [valueField]="'value'"
                            (valueChange)="PiezaValueChangedAnnadirOp($event)"
                            [(value)]="JpiezasSelected"
                            [class.requerido]="requirePieza"
                            placeholder="{{ 'pieza' | translate }}"
                            [kendoDropDownFilter]="{operator: 'contains'}">
            </kendo-combobox>
          </div>
        </div>
      </div>

      <!-- PARTE -->
      <div class="col-md-3" *ngIf="!user.ocultarParte">
        <div class="form-group">
          <label>{{ 'parte' | translate }}</label>
          <div class="caja">
            <kendo-combobox [data]="Jpartes"
                            [textField]="'text'"
                            [valueField]="'value'"
                            (valueChange)="ParteValueChangedAnnadirOp($event)"
                            [(value)]="JpartesSelected"
                            [class.requerido]="requireParte"
                            placeholder="{{ 'parte' | translate }}"
                            [kendoDropDownFilter]="{operator: 'contains'}">
            </kendo-combobox>
          </div>
        </div>
      </div>

      <!-- OPERACION -->
      <div class="col-md-3">
        <div class="form-group">
          <label>{{ 'operacion' | translate }}</label>
          <div class="caja">
            <kendo-combobox [data]="Jprogramas"
                            [textField]="'text'"
                            [valueField]="'value'"
                            (valueChange)="operacionValueChanged($event)"
                            [(value)]="JprogramasSelected"
                            [class.requerido]="requireOperacion"
                            placeholder="{{ 'programa' | translate }}"
                            [kendoDropDownFilter]="{operator: 'contains'}">
            </kendo-combobox>
          </div>
        </div>
      </div>
    </div>

    <!-- CANTIDADES -->
    <div class="row" *ngIf="esSoloParada && verCantidades">
      <div class="col-md-3">
        <kendo-label text="{{ 'cantidadTerminada' | translate }}"></kendo-label>
        <div class="caja">
          <div class="caja">
            <kendo-numerictextbox [decimals]="0" [min]="0" [max]="cantidadTerminadaMax" maxlength="50" [(value)]="PPcantidadTerminada" (valueChange)="onChangeCantidad()"></kendo-numerictextbox>
            <kendo-label text="&nbsp;/&nbsp;{{cantidadTotal}}"></kendo-label>
          </div>
        </div>
      </div>
      <div class="col-md-3">
        <kendo-label text="{{ 'cantidadAchatarrada' | translate }}"></kendo-label>
        <div class="caja">
          <div class="caja">
            <kendo-numerictextbox [decimals]="0" [min]="0" [max]="cantidadAchatarradaMax" maxlength="50" [(value)]="PPcantidadAchatarrada" (valueChange)="onChangeCantidad()"></kendo-numerictextbox> 
            
          </div>
        </div>
      </div>
      <div class="col-md-3">
        <kendo-label text="{{ 'cantidadApartada' | translate }}"></kendo-label>
        <div class="caja">
          <div class="caja">
              <kendo-numerictextbox [decimals]="0" [min]="0" [max]="cantidadApartadaMax" maxlength="50" [(value)]="PPcantidadApartada" (valueChange)="onChangeCantidad()"></kendo-numerictextbox>
          </div>
        </div>
      </div>
      <div class="col-md-2">
          <kendo-label text="{{'terminado' |translate}}"></kendo-label>
          <div class="caja">
            <kendo-switch id="terminado" [(ngModel)]="terminado" [onLabel]="' '" [offLabel]="' '" [(value)]="isTerminado"> </kendo-switch>
          </div>
      </div>
    </div>

    <!--  TIPO -->
    <div class="row" *ngIf="esSoloParada && isOffline">
      <div class="col-md-6">
        <div class="form-group">
          <label>{{ 'tipo' | translate }}</label>
          <div class="caja">
            <kendo-combobox [data]="JtiposAnnadirOperacion"
                            [textField]="'text'"
                            [valueField]="'value'"
                            [(value)]="JtiposSelected"
                            (valueChange)="tipoChanged($event)"
                            placeholder="{{ 'tipo' | translate }}"
                            [kendoDropDownFilter]="{operator: 'contains'}">
            </kendo-combobox>
          </div>
        </div>
      </div>
    </div>

    <!-- NSERIE, LOTE, COLADA -->
    <div class="row" *ngIf="esSoloParada">
      <!-- NSERIE -->
      <div class="col-md-3" *ngIf="verNSerie">
        <div class="form-group">
          <label>{{ 'nSerie' | translate }}</label>
          <div class="caja">
            <kendo-combobox [data]="JnSerie"
                            [textField]="'nSerie'"
                            [valueField]="'rn'"
                            [(value)]="JnSerieSelected"
                            placeholder="{{ 'nSerie' | translate }}"
                            [kendoDropDownFilter]="{operator: 'contains'}">
            </kendo-combobox>
          </div>
        </div>
      </div>
      <div class="col-md-3" *ngIf="(nSerieTextInput) && (JnSerieSelected == undefined)">
        <div class="form-group">
          <kendo-label text="{{ 'nuevonSerie' | translate }}"></kendo-label>
          <div class="caja">
            <kendo-textbox [(value)]="nuevoNserie" [maxlength]="50"></kendo-textbox>
          </div>
        </div>
      </div>
      <!-- CANTIDAD LOTE -->
      <div class="col-md-3" *ngIf="verLotes">
        <div class="form-group">
          <kendo-label text="{{ 'cantidadLote' | translate }}"></kendo-label>
          <div class="caja">
            <div class="caja">
              <kendo-numerictextbox [decimals]="0" [min]="1" [max]="999999" maxlength="50" [value]="cantidadLote"></kendo-numerictextbox>
            </div>
          </div>
        </div>
      </div>
      <!-- COLADA -->
      <div class="col-md-3" *ngIf="verColada">
        <div class="form-group">
          <kendo-label text="{{ 'colada' | translate }}"></kendo-label>
          <div class="caja">
              <!-- <kendo-numerictextbox [decimals]="0" [min]="1" [max]="999999" maxlength="50" [value]="cantidadLote"></kendo-numerictextbox> 
              <kendo-textbox [(value)]="colada" [maxlength]="50"></kendo-textbox> -->
              <kendo-combobox [data]="JnColada"
                          [textField]="'colada'"
                          [valueField]="'colada'"
                          [(value)]="JnColadaSelected"
                          placeholder="{{ 'colada' | translate }}"
                          [kendoDropDownFilter]="{operator: 'contains'}"
                          [allowCustom]="true"
                          (valueChange)="coladaChanged($event)">
          </kendo-combobox>
          </div>
        </div>
      </div>
    </div>

  </div>
    <div class="modal-footer">
      <button type="button" class="btn btn-danger" (click)="modal.dismiss('cancel click')">{{ 'cancelar' | translate }}</button>
      <button type="button" class="btn btn-primary" (click)="annadirOperacion()" *ngIf="verAceptar" [disabled]="this.user.validador < 2">{{ 'aceptar' | translate }}</button>
    </div>
</ng-template>

<!-- POPUP ASIGNAR PERDIDA -->
<ng-template #popupAsignarPerdida let-modal>
  <div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title">{{ 'asignarPerdida' | translate }}</h4>
    <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <div *ngIf="esParadaSinJustificar">
      <div class="row">
        <!-- fecha ini -->
        <div class="col-md-3"><label>{{ 'inicio' | translate }}</label></div>
        <!-- fecha fin -->
        <div class="col-md-3"><label>{{ 'fin' | translate }}</label></div>
        <!-- observacion -->
        <div class="col-md-4"><label>{{ 'perdidas' | translate }}</label></div>
      </div>
      <div *ngFor="let perdida of asignarMultiperdida">
        <div class="row">
          <!-- fecha ini -->
          <div class="col-md-3">
            <kendo-timepicker [(value)]="perdida.fechaIni"
                      class="form-control"
                      [disabled]="true">
            </kendo-timepicker>
          </div>
          <!-- fecha fin -->
          <div class="col-md-3">
            <kendo-timepicker [(value)]="perdida.fechaFin"
                      class="form-control"
                      [min]="perdida.fechaMin"
                      [max]="perdida.fechaMax"
                      [disabled]="false"
                      (valueChange)="addPerdida(perdida)">
            </kendo-timepicker>
          </div>
          <!-- observacion -->
          <div class="col-md-4">
            <button *ngIf="perdida.idPerdida == -1" type="button" class="btn" (click)="clickEnsennarPerdidas(popupEnsennarPerdidas, perdida)">{{ 'sinAsignar' | translate }}</button>
            <button *ngIf="perdida.idPerdida != -1" type="button" class="btn" (click)="clickEnsennarPerdidas(popupEnsennarPerdidas, perdida)">{{ perdida.perdida }}</button>
          </div>
          <!-- eliminar linea -->
          <div class="col-md-2">  
            <button Class="flt-eliminar" (click)="eliminar(perdida)" *ngIf="perdida.id != 0"> <i class="fas fa-times"></i> </button>
          </div>
        </div>
      </div>
    </div>
    <div *ngIf="esParadaJustificada">
      <!-- observacion -->
      <div class="row">
        <div class="col-md-4"><label>{{ 'observacion' | translate }}</label></div>
        <div class="col-md-4">
          <button *ngIf="perdidaAsignada.idPerdida == -1" type="button" class="btn" (click)="clickEnsennarPerdidas(popupEnsennarPerdidas, perdidaAsignada)">{{ 'sinAsignar' | translate }}</button>
          <button *ngIf="perdidaAsignada.idPerdida != -1" type="button" class="btn" (click)="clickEnsennarPerdidas(popupEnsennarPerdidas, perdidaAsignada)">{{ perdidaAsignada.perdida }}</button>
        </div>
        <!-- eliminar linea -->
        <div class="col-md-2">  
          <button Class="flt-eliminar" (click)="eliminar(perdidaAsignada)" *ngIf="perdidaAsignada.idPerdida != -1"> <i class="fas fa-times"></i> </button>
        </div>
      </div>
    </div>
    <!-- si no es parada sin justificar -->
    <div class="row" *ngIf="!esParadaSinJustificar && !esParadaJustificada">
      <div class="col-md-12">
        <span> * {{ 'soloParadaSinJustificar' | translate }} </span>
      </div>
    </div>
  </div>
    <div class="modal-footer">
      <div class="float-left" *ngIf="ensennarJustificarHastaFin">
        <kendo-switch class="kendo-switch-tipo2" style="width: 265px;" [(ngModel)]="justificarHastaFinProceso"
                      [onLabel]="justificarHastaFin" [offLabel]="justificarHastaFin">
        </kendo-switch>
      </div>
      <div class="float-left">
        <button type="button" class="btn btn-danger mr-1" (click)="modal.dismiss('cancel click')">{{ 'cancelar' | translate }}</button>
        <button type="button" class="btn btn-primary" (click)="validarPerdidas()" [disabled]="this.user.validador < 2">{{ 'validar' | translate }}</button>
      </div>
    </div>
</ng-template>

<!-- POPUP POSIBLES PERDIDAS -->
<ng-template #popupEnsennarPerdidas let-modal>
  <div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title">{{ 'perdidas' | translate }}</h4>
    <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click'); observacionPerdida = ''">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <kendo-grid [kendoGridBinding]="dataPerdidasGrid" [sortable]="true" [navigable]="true"
                filterable="menu" (cellClick)="elegirPerdida($event, popupRequiereObservacion); modal.dismiss('cancel click')" >
      <kendo-grid-column width="10%" field="grupoPerdida" title="{{ 'perdidasGrupo' | translate}}"> </kendo-grid-column>
      <kendo-grid-column width="10%" field="perdida" title="{{ 'perdidas' | translate}}"> </kendo-grid-column>
      <kendo-grid-column width="10%" field="subperdida" title="{{ 'subperdidas' | translate}}"> </kendo-grid-column>
      <kendo-grid-column width="10%" field="observacion" title="{{ 'observacion' | translate}}"> </kendo-grid-column>
      <kendo-grid-messages filter="{{'filter' | translate}}" filterAfterOperator="{{'filterAfterOperator' | translate}}"
      filterAfterOrEqualOperator="{{'filterAfterOrEqualOperator' | translate}}"
      filterAndLogic="{{'filterAndLogic' | translate}}" filterBeforeOperator="{{'filterBeforeOperator' | translate}}"
      filterBeforeOrEqualOperator="{{'filterBeforeOrEqualOperator' | translate}}"
      filterBooleanAll="{{'filterBooleanAll' | translate}}" filterClearButton="{{'filterClearButton' | translate}}"
      filterContainsOperator="{{'filterContainsOperator' | translate}}"
      filterDateToday="{{'filterDateToday' | translate}}" filterDateToggle="{{'filterDateToggle' | translate}}"
      filterEndsWithOperator="{{'filterEndsWithOperator' | translate}}"
      filterEqOperator="{{'filterEqOperator' | translate}}" filterFilterButton="{{'filterFilterButton' | translate}}"
      filterGtOperator="{{'filterGtOperator' | translate}}" filterGteOperator="{{'filterGteOperator' | translate}}"
      filterIsEmptyOperator="{{'filterIsEmptyOperator' | translate}}" filterIsFalse="{{'filterIsFalse' | translate}}"
      filterIsNotEmptyOperator="{{'filterIsNotEmptyOperator' | translate}}"
      filterIsNotNullOperator="{{'filterIsNotNullOperator' | translate}}"
      filterIsNullOperator="{{'filterIsNullOperator' | translate}}" filterIsTrue="{{'filterIsTrue' | translate}}"
      filterLtOperator="{{'filterLtOperator' | translate}}" filterLteOperator="{{'filterLteOperator' | translate}}"
      filterNotContainsOperator="{{'filterNotContainsOperator' | translate}}"
      filterNotEqOperator="{{'filterNotEqOperator' | translate}}"
      filterNumericDecrement="{{'filterNumericDecrement' | translate}}"
      filterNumericIncrement="{{'filterNumericIncrement' | translate}}" filterOrLogic="{{'filterOrLogic' | translate}}"
      filterStartsWithOperator="{{'filterStartsWithOperator' | translate}}" loading="{{'loading' | translate}}"
      groupPanelEmpty="{{'groupPanelEmpty' | translate}}" lock="{{'lock' | translate}}" unlock="{{'unlock' | translate}}"
      noRecords="{{'norecords' | translate}}" pagerItems="" pagerOf="/"></kendo-grid-messages>
    </kendo-grid>         
  
  </div>
    <div class="modal-footer">
      <button type="button" class="btn btn-danger" (click)="modal.dismiss('cancel click')">{{ 'cancelar' | translate }}</button>
      <button type="button" class="btn btn-primary" [disabled]="this.user.validador < 2">{{ 'validar' | translate }}</button>
    </div>
</ng-template>

<!-- POPUP REQUIERE OBSERVACION LA PERDIDA -->
<ng-template #popupRequiereObservacion let-modal>
  <div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title">{{ 'requiereObservacion' | translate }}</h4>
    <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <textarea kendoTextArea [(value)]="observacionPerdida"></textarea>
  </div>
    <div class="modal-footer">
      <button type="button" class="btn btn-danger" (click)="modal.dismiss('cancel click'); observacionPerdida = ''">{{ 'cancelar' | translate }}</button>
      <button type="button" class="btn btn-primary" (click)="guardarObservacion()" [disabled]="this.user.validador < 2">{{ 'aceptar' | translate }}</button>
    </div>
</ng-template>

<!-- POPUP ALERT-->
<!-- POPUP: Eliminar -->
<div class="popup-cont" *ngIf="popupPreguntar">
  <div class="popup" style="width: 300px;">
    <div class="popup-header">
      <h3>
        <label>{{'perdida' | translate }}</label>
      </h3>
    </div>
    <div class="popup-body">
      <div>
        <label class="crontol-label m-1">{{ 'errorTienePerdida' | translate }}</label>
      </div>
    </div>
    <div class="popup-footer text-rigth d-flex  justify-content-end mt-2">
      <button type="button" class="btn btn-danger mr-1" (click)="this.popupPreguntar = false;this.popupRespuesta= false;">{{ 'cancelar' | translate }}</button>
      <button type="button" class="btn btn-primary" (click)="cambiarDePerdidasAOperacionRegistro()" [disabled]="this.user.bonos<2">{{ 'aceptar' | translate }}</button>
    </div>
  </div>
</div>

<!-- POPUP DISTRIBUCION PREPARACION-->
<ng-template #popupDistribucionTiempos let-modal>
  <div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title">{{ 'asignarPreparacion' | translate }}</h4>
    <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click'); this.distribucionTiemposOpen = false;">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">

    <!-- FECHA -->
    <div class="row">
      <div class="col-md-6">
        <div class="form-group">
          <label>{{ 'inicio' | translate }}</label>
          <div class="caja">
            <kendo-timepicker [(value)]="fechaIni"
                              [min]="fechaIniMin"
                              [max]="fechaFin"
                              [autoCorrect]="true"
                                  class="form-control">
            </kendo-timepicker>
          </div>
        </div>
      </div>
      <div class="col-md-6">
        <div class="form-group">
          <label>{{ 'fin' | translate }}</label>
          <div class="caja">
            <kendo-timepicker [(value)]="fechaFin"
                              [min]="fechaIni"
                              [max]="fechaFinMax"
                              [autoCorrect]="true"
                                  class="form-control">
            </kendo-timepicker>
          </div>
        </div>
      </div>
    </div>
    
    <kendo-grid [kendoGridBinding]="dataDistribucionTiempos" [sortable]="true" [navigable]="true"
                filterable="menu" 
                [height]="350">
      <kendo-grid-column width="10%" field="orden" title="{{ 'orden' | translate}}"> </kendo-grid-column>
      <kendo-grid-column width="30%" field="referencia" title="{{ 'referencia' | translate}}">
        <ng-template kendoGridCellTemplate let-dataItem>
          <label *ngIf="dataItem.idOperacion!=-1">{{ dataItem.numeroOF }} - {{dataItem.nombreCliente}}</label>
          <br>
          <label *ngIf="dataItem.idOperacion!=-1">{{dataItem.nombrePieza}} - {{dataItem.nombreOperacion}}</label>
        </ng-template></kendo-grid-column>
      <kendo-grid-column width="10%" field="nSerie" *ngIf="user.verNSerie" title="{{ 'nserie' | translate}}"> </kendo-grid-column>
      <kendo-grid-column width="10%" field="colada" *ngIf="user.verColada" title="{{ 'colada' | translate}}"> </kendo-grid-column>
      <kendo-grid-column width="10%" field="lote" *ngIf="user.verLote" title="{{ 'lote' | translate}}"> </kendo-grid-column>
      <kendo-grid-column width="15%" field="porcentaje" title="%">
        <ng-template kendoGridCellTemplate let-dataItem>
          <kendo-numerictextbox *ngIf="dataItem.idOperacion!=-1" class="form-control" [disabled]="this.configuracion.idDistribucionTiempoPreparacion != 1"
                                [(value)]="dataItem.porcentaje" [format]="'#.00'" [min]="0" [max]="100"></kendo-numerictextbox>
        </ng-template></kendo-grid-column>
      <kendo-grid-column width="15%" field="accion" title="{{ 'accion' | translate}}">
        <ng-template kendoGridCellTemplate let-dataItem>
          <button class="bonos-asignarPreparacion-Editar" [ngClass]="dataItem.orden > 0 ? 'icon-editar-alt' : 'icon-mas-alt'" (click)="distribucionTiemposAsignarOF(dataItem.orden)"></button>
          <button class="bonos-asignarPreparacion-Eliminar icon-equis" *ngIf="dataItem.orden > 0" (click)="distribucionTiemposEliminar(dataItem.orden)"></button>
        </ng-template>
      </kendo-grid-column>
      <kendo-grid-messages filter="{{'filter' | translate}}" filterAfterOperator="{{'filterAfterOperator' | translate}}"
      filterAfterOrEqualOperator="{{'filterAfterOrEqualOperator' | translate}}"
      filterAndLogic="{{'filterAndLogic' | translate}}" filterBeforeOperator="{{'filterBeforeOperator' | translate}}"
      filterBeforeOrEqualOperator="{{'filterBeforeOrEqualOperator' | translate}}"
      filterBooleanAll="{{'filterBooleanAll' | translate}}" filterClearButton="{{'filterClearButton' | translate}}"
      filterContainsOperator="{{'filterContainsOperator' | translate}}"
      filterDateToday="{{'filterDateToday' | translate}}" filterDateToggle="{{'filterDateToggle' | translate}}"
      filterEndsWithOperator="{{'filterEndsWithOperator' | translate}}"
      filterEqOperator="{{'filterEqOperator' | translate}}" filterFilterButton="{{'filterFilterButton' | translate}}"
      filterGtOperator="{{'filterGtOperator' | translate}}" filterGteOperator="{{'filterGteOperator' | translate}}"
      filterIsEmptyOperator="{{'filterIsEmptyOperator' | translate}}" filterIsFalse="{{'filterIsFalse' | translate}}"
      filterIsNotEmptyOperator="{{'filterIsNotEmptyOperator' | translate}}"
      filterIsNotNullOperator="{{'filterIsNotNullOperator' | translate}}"
      filterIsNullOperator="{{'filterIsNullOperator' | translate}}" filterIsTrue="{{'filterIsTrue' | translate}}"
      filterLtOperator="{{'filterLtOperator' | translate}}" filterLteOperator="{{'filterLteOperator' | translate}}"
      filterNotContainsOperator="{{'filterNotContainsOperator' | translate}}"
      filterNotEqOperator="{{'filterNotEqOperator' | translate}}"
      filterNumericDecrement="{{'filterNumericDecrement' | translate}}"
      filterNumericIncrement="{{'filterNumericIncrement' | translate}}" filterOrLogic="{{'filterOrLogic' | translate}}"
      filterStartsWithOperator="{{'filterStartsWithOperator' | translate}}" loading="{{'loading' | translate}}"
      groupPanelEmpty="{{'groupPanelEmpty' | translate}}" lock="{{'lock' | translate}}" unlock="{{'unlock' | translate}}"
      noRecords="{{'norecords' | translate}}" pagerItems="" pagerOf="/"></kendo-grid-messages>
    </kendo-grid>   

  </div>
    <div class="modal-footer">
      <div class="float-left">
        <button type="button" class="btn btn-danger mr-1" (click)="modal.dismiss('cancel click'); this.distribucionTiemposOpen = false;">{{ 'cancelar' | translate }}</button>
        <button type="button" class="btn btn-primary" (click)="aceptarDistribucionTiempos()" [disabled]="this.user.validador < 2">{{ 'aceptar' | translate }}</button>
      </div>
    </div>
</ng-template>

<ng-template #popupAvisoPreparacion let-modal>
  <div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title">{{ 'aviso' | translate }}</h4>
    <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">  
    <label title="avisoPreparacionLabel"> {{ avisoPreparacionLabel }}</label>
  </div>
    <div class="modal-footer">
      <div class="float-left">
        <button type="button" class="btn btn-primary" (click)="modal.dismiss('Cross click')" [disabled]="this.user.validador < 2">{{ 'aceptar' | translate }}</button>
      </div>
    </div>
</ng-template>

<!-- POPUP QUITAR TERMINADO DE LA OPERACION -->
<ng-template #popupAbrirOperacion let-modal>
  <div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title">{{ 'deseaVolverAAbrir' | translate }}</h4>
    <button type="button" class="close" aria-label="Close" (click)="cancelarAbrirOperacion()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">  
    <label title="avisoPreparacionLabel"> {{ 'avisoAbrirDesdeProyectos' | translate }}</label>
  </div>
    <div class="modal-footer">
      <div class="float-left">
        <button type="button" class="btn btn-danger mr-1" (click)="cancelarAbrirOperacion()" [disabled]="this.user.validador < 2">{{ 'cancelar' | translate }}</button>
        <button type="button" class="btn btn-primary" (click)="aceptarAbrirOperacion()" [disabled]="this.user.validador < 2">{{ 'aceptar' | translate }}</button>
      </div>
    </div>
</ng-template>

<!-- POPUP QUITAR TERMINADO DE LA PIEZA -->
<ng-template #popupAbrirPieza let-modal>
  <div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title">{{ 'deseaVolverAAbrirPieza' | translate }}</h4>
    <button type="button" class="close" aria-label="Close" (click)="cancelarAbrirPieza()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">  
    <label title="avisoPreparacionLabel"> {{ 'avisoAbrirDesdeProyectosPieza' | translate }}</label>
  </div>
    <div class="modal-footer">
      <div class="float-left">
        <button type="button" class="btn btn-danger mr-1" (click)="cancelarAbrirPieza()" [disabled]="this.user.validador < 2">{{ 'cancelar' | translate }}</button>
        <button type="button" class="btn btn-primary" (click)="aceptarAbrirPieza()" [disabled]="this.user.validador < 2">{{ 'aceptar' | translate }}</button>
      </div>
    </div>
</ng-template>

<!-- POPUP QUITAR TERMINADO DE LA OPERACION Y PIEZA -->
<ng-template #popupAbrirOperacionYPieza let-modal>
  <div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title">{{ 'deseaVolverAAbrirOperacionYPieza' | translate }}</h4>
    <button type="button" class="close" aria-label="Close" (click)="cancelarAbrirOperacionYPieza()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">  
    <label title="avisoPreparacionLabel"> {{ 'avisoAbrirDesdeProyectos' | translate }}</label>
  </div>
    <div class="modal-footer">
      <div class="float-left">
        <button type="button" class="btn btn-danger mr-1" (click)="cancelarAbrirOperacionYPieza()" [disabled]="this.user.validador < 2">{{ 'cancelar' | translate }}</button>
        <button type="button" class="btn btn-primary" (click)="aceptarAbrirOperacionYPieza()" [disabled]="this.user.validador < 2">{{ 'aceptar' | translate }}</button>
      </div>
    </div>
</ng-template>

<!-- POPUP VOLVER A TERMINAR OPERACION -->
<ng-template #popupTerminarOperacion let-modal>
  <div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title">{{ 'operacionTerminada' | translate }}</h4>
    <button type="button" class="close" aria-label="Close" (click)="cancelarTerminarOperacion()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">  
    <label title="{{ 'deseaQuitarTerminado' | translate }}"> {{ 'deseaQuitarTerminado' | translate }}</label>
  </div>
    <div class="modal-footer">
      <div class="float-left">
        <button type="button" class="btn btn-danger mr-1" (click)="cancelarTerminarOperacion()" [disabled]="this.user.validador < 2">{{ 'cancelar' | translate }}</button>
        <button type="button" class="btn btn-primary" (click)="aceptarTerminarOperacion()" [disabled]="this.user.validador < 2">{{ 'aceptar' | translate }}</button>
      </div>
    </div>
</ng-template>

<!-- POPUP DESVALIDAR -->
<ng-template #popupDesvalidar let-modal>
  <div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title">{{ 'desvalidar' | translate }}</h4>
    <button type="button" class="close" aria-label="Close" (click)="cancelarDesvalidar()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">  
    <label title="{{ 'datosVolverAlERP' | translate }}"> {{ 'datosVolverAlERP' | translate }}</label>
  </div>
    <div class="modal-footer">
      <div class="float-left">
        <button type="button" class="btn btn-danger mr-1" (click)="cancelarDesvalidar()" [disabled]="this.user.validador < 2">{{ 'cancelar' | translate }}</button>
        <button type="button" class="btn btn-primary" (click)="guardarDesvalidar()" [disabled]="this.user.validador < 2">{{ 'aceptar' | translate }}</button>
      </div>
    </div>
</ng-template>


<div *ngIf="loadingPanel" class="k-i-loading" style="z-index: 3;"></div>
